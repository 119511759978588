<script lang="ts">
import Vue, { computed, PropOptions, reactive, ref, watch } from 'vue';

export const chatbotProps = {
  partnerId: {
    type: String,
  },
  clientId: {
    type: String,
  },
} satisfies Record<string, PropOptions>;
</script>

<script setup lang="ts">
import { getServices } from '@/services';
import { Partner, Client } from 'ah-api-gateways';
import { VButton } from 'ah-common-lib/src/common/components';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { FormEvent } from 'ah-common-lib/src/form/interfaces';
import { textField } from 'ah-common-lib/src/form/models';
import { generateUUID } from 'ah-common-lib/src/helpers/uuid';
import LoadingIcon from 'ah-common-lib/src/icons/components/LoadingIcon.vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useFEFeatureFlag } from 'ah-common-stores/src/stores/featureFlagStore';
import { RequestState } from 'ah-requests';
import markdownit from 'markdown-it';

const props = defineProps(chatbotProps);

const mdParser = markdownit();

const useIdentityInAiChatBot = useFEFeatureFlag('useIdentityInAiChatBot');

// NOTE - Configuring markdown to open links in a new tab as per https://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md#renderer
const defaultRender =
  mdParser.renderer.rules.link_open ||
  function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
  };

mdParser.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  tokens[idx].attrSet('target', '_blank');
  return defaultRender(tokens, idx, options, env, self);
};

const requestManager = useRequestManager().manager;

const services = getServices();

interface ChatbotMessage {
  id: string;
  content: string;
  type: 'query' | 'response';
  state: RequestState;
  client?: {
    id: string;
    name: string;
  };
  partner?: {
    id: string;
    name: string;
  };
}

const queryInputFM = reactive(
  makeFormModel({
    name: 'queryInputForm',
    fieldType: 'form',
    fields: [
      textField('query', '', {
        required: true,
        hideErrors: true,
      }),
    ],
  })
);

const client = ref<Client>();

const partner = ref<Partner>();

watch(
  () => [props.clientId, props.partnerId],
  () => {
    /**
     * NOTE - as useIdentityInAiChatBot prevents partner/client from EVER loading,
     * it effectively acts as a feature flag for the whole feature of partner/client context, disabling both the visual aspects and the data sent to the API`
     */
    if (!useIdentityInAiChatBot) {
      return;
    }
    if (props.clientId) {
      requestManager.cancel('loadPartner');
      partner.value = undefined;
      requestManager
        .sameOrCancelAndNew('loadClient', services.client.getClient(props.clientId, false), props.clientId)
        .subscribe((loadedClient) => {
          client.value = loadedClient;
        });
    } else if (props.partnerId) {
      requestManager.cancel('loadClient');
      client.value = undefined;
      requestManager
        .sameOrCancelAndNew('loadPartner', services.partner.getPartner(props.partnerId), props.partnerId)
        .subscribe((loadedPartner) => {
          partner.value = loadedPartner;
        });
    } else {
      partner.value = undefined;
      client.value = undefined;
    }
  },
  { immediate: true }
);

const messagesGroup = ref<Vue>();

const query = computed<string>(() => (queryInputFM.query || '').trim());

async function withBottomScrollCheck(fn: () => void, forceBottomScroll = false) {
  const el = messagesGroup.value?.$el! as HTMLDivElement;
  const atBottom = el.scrollTop >= el.scrollHeight - el.offsetHeight;

  await fn();

  if (atBottom || forceBottomScroll) {
    setTimeout(() => {
      el.scrollTop = el.scrollHeight - el.offsetHeight;
    });
  }
}

function pushMessage(message: ChatbotMessage) {
  if (messages.value.includes(message)) {
    return;
  }

  messages.value.push(message);
}

function deleteMessage(message: ChatbotMessage) {
  const index = messages.value.indexOf(message);
  if (index > -1) {
    messages.value.splice(index, 1);
  }
}

function sendQuery() {
  if (!query.value) {
    return;
  }
  const requestMessage: ChatbotMessage = {
    id: generateUUID(),
    content: mdParser.render(query.value),
    state: 'success',
    type: 'query',
    client: client.value ? { id: client.value.id, name: client.value.name } : undefined,
    partner: partner.value ? { id: partner.value.id, name: partner.value.name } : undefined,
  };

  withBottomScrollCheck(() => pushMessage(requestMessage), true);

  const responseMessage = reactive<ChatbotMessage>({
    id: generateUUID(),
    content: '...',
    state: 'pending',
    type: 'response',
    client: client.value ? { id: client.value.id, name: client.value.name } : undefined,
    partner: partner.value ? { id: partner.value.id, name: partner.value.name } : undefined,
  });

  const responseMessageTimeout = setTimeout(() => withBottomScrollCheck(() => pushMessage(responseMessage)), 600);

  requestManager
    .new(
      'message',
      services.aiChatbot.queryChatbot({
        query: query.value,
        clientId: client.value?.id,
        partnerId: partner.value?.id || client.value?.partner?.id,
      })
    )
    .subscribe(
      (response) => {
        clearTimeout(responseMessageTimeout);
        withBottomScrollCheck(() => {
          responseMessage.content = mdParser.render(response.message);
          responseMessage.state = 'success';
          pushMessage(responseMessage);
        });
      },
      () => {
        clearTimeout(responseMessageTimeout);
        deleteMessage(responseMessage);
        requestMessage.state = 'error';
      }
    );

  queryInputFM.query = '';
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-submit' && query.value) {
    sendQuery();
  }
}

const messages = ref<ChatbotMessage[]>([]);
</script>

<template>
  <div class="chatbot-chatroom">
    <div class="identity-banner" v-if="partner || client">
      Currently chatting as the {{ partner ? 'Partner' : 'Client' }} '{{
        (partner && partner.name) || (client && client.name) || ''
      }}'
    </div>
    <TransitionGroup name="slide-and-fade" tag="div" class="messages" ref="messagesGroup">
      <p v-for="message in messages" :key="message.id" :class="message.type">
        <LoadingIcon v-if="message.state === 'pending'" />
        <template v-else>
          <span class="identity-info" v-if="message.partner || message.client">
            {{ message.type === 'query' ? 'Sent' : 'Received' }} as the {{ message.partner ? 'Partner' : 'Client' }} '{{
              (message.partner && message.partner.name) || (message.client && message.client.name) || ''
            }}':
          </span>
          <span v-html="message.content"></span>
        </template>
        <i
          v-if="message.state === 'error'"
          v-b-tooltip="'Message failed to send'"
          class="error-icon fa-solid fa-triangle-exclamation"
        ></i>
      </p>
    </TransitionGroup>
    <div class="input-area">
      <ValidatedForm :fm="queryInputFM" class="input-form" @form-event="onFormEvent" />
      <VButton @click="sendQuery" :disabled="!query" class="btn-outline-primary input-submit"> Send </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slide-and-fade-enter,
.slide-and-fade-leave-active {
  opacity: 0 !important;
  transform: translateY(-10px);
}

.slide-and-fade-enter-active,
.slide-and-fade-leave-active {
  transition: all $transition-time-fast ease;
}

.chatbot-chatroom {
  display: grid;
  grid-template-areas: 'identity-banner' 'messages' 'input';
  grid-template-rows: min-content 1fr min-content;

  .identity-banner {
    grid-area: identity-banner;
    background-color: $x-ui-light-tag-orange-bg;
    padding: 0.5em;
    border-bottom: 1px solid $x-ui-light-tag-orange-border;
    color: $x-ui-light-tag-orange-text;
  }

  .input-area {
    padding-bottom: 1em;
    grid-area: input;
    display: grid;
    grid-template-areas: 'input-field button';
    grid-template-columns: 1fr min-content;

    .input-form {
      padding-left: 1em;
    }

    .input-submit {
      padding: 0 1em;
      margin: 0 1em;
    }

    ::v-deep {
      .field-group-wrapper {
        margin-bottom: 0;
      }
    }
  }

  .messages {
    grid-area: messages;
    border-bottom: 1px solid $common-color-lightGrey;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto 1rem;
    padding: 0.5rem 1.5rem;
    overflow: auto;

    p {
      border-radius: 1.15rem;
      line-height: 1.25;
      max-width: 75%;
      padding: 0.5rem 0.875rem;
      position: relative;
      word-wrap: break-word;
      margin: 0.5rem 0;
      width: fit-content;

      .identity-info {
        font-style: italic;
        font-size: 0.8em;
        opacity: 0.75;
      }

      .error-icon {
        position: absolute;
        left: -1.5em;
        font-size: 1.4em;
        color: $common-color-darkRed;
      }

      &::before,
      &::after {
        bottom: -0.1rem;
        content: '';
        height: 1rem;
        position: absolute;
      }

      &.query {
        align-self: flex-end;
        background-color: getColor($color-primary);
        color: $common-color-white;

        &::before {
          border-bottom-left-radius: 0.8rem 0.7rem;
          border-right: 1rem solid getColor($color-primary);
          right: -0.35rem;
          transform: translate(0, -0.1rem);
        }

        &::after {
          background-color: $common-color-white;
          border-bottom-left-radius: 0.5rem;
          right: -40px;
          transform: translate(-30px, -2px);
          width: 10px;
        }
        & ~ & {
          margin: 0.25rem 0 0;
        }

        & ~ &:not(:last-child) {
          margin: 0.25rem 0 0;
        }

        & ~ &:last-child {
          margin-bottom: 0.5rem;
        }
      }

      &.response {
        align-items: flex-start;
        background-color: $common-color-lightGreyShadow;
        color: $common-color-black;

        &::before {
          border-bottom-right-radius: 0.8rem 0.7rem;
          border-left: 1rem solid $common-color-lightGreyShadow;
          left: -0.35rem;
          transform: translate(0, -0.1rem);
        }

        &::after {
          background-color: $common-color-white;
          border-bottom-right-radius: 0.5rem;
          left: 20px;
          transform: translate(-30px, -2px);
          width: 10px;
        }
      }

      &.emoji {
        background: none;
        font-size: 2.5rem;

        &::before {
          content: none;
        }
      }

      .no-tail::before {
        display: none;
      }
    }
  }
}
</style>
